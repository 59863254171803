import styled from 'styled-components';
import { devices, renderStyledElementStyles } from '../../../../helpers';

export const ImageWrapper = styled.div`
  ${({ theme, displayType }) =>
    displayType &&
    theme.module.dStyles.elements?.imageWrapper?.alignStyles?.[displayType]
      ? renderStyledElementStyles(
          theme,
          theme.module.dStyles.elements?.imageWrapper?.alignStyles?.[
            displayType
          ],
        )
      : renderStyledElementStyles(
          theme,
          theme.module.dStyles.elements?.imageWrapper,
        )};

  @media only screen and (min-width: ${devices.breakpoints.tablet}) {
    display: none;
  }
`;
