import React from 'react';
import PropTypes from 'prop-types';
import { ImageWrapper } from '../../styles';

export const HeroModuleImage = ({ backgroundImage, displayType }) => (
  <ImageWrapper
    data-background-image={backgroundImage}
    className="lazy"
    data-testid="venue-module-image"
    displayType={displayType}
  />
);

HeroModuleImage.propTypes = {
  backgroundImage: PropTypes.string,
  displayType: PropTypes.string,
};
